import { InstructionDsp } from 'types/instruction_type'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { $GOOGLEADS, dspConfig } from '../../config/dspConfig'

type ObjType = {[x: string]: any}

@Component({})
export class generalMixin extends Vue {
  props: {item: any}
  checkElement (element: string): Boolean {
    return element !== undefined && element !== null && element !== ''
  }

  computedFilter (value: ObjType): Boolean {
    let propkeys = value.value.split('.')
    let prop = this.props.item != null && this.checkElement(this.props.item[propkeys[0]]) ? this.props.item[propkeys[0]] : null

    for (let key in propkeys) {
      if (prop === null) {
        return false
      }

      if (key === '0') {
        continue
      }
      prop = this.checkElement(prop[propkeys[key]]) ? prop[propkeys[key]] : null
    }

    return prop !== null
  }

  computedMapping (value: ObjType): ObjType {
    let propkeys = value.value.split('.')
    let prop = this.props.item[propkeys[0]]

    for (let key in propkeys) {
      if (key === '0') continue
      prop = prop[propkeys[key]]
    }

    // add potential added properties
    let addedProp: ObjType = {}

    for (let i in value) {
      if (i !== 'label' && i !== 'value') {
        addedProp[i] = typeof value[i] !== 'function' ? value[i] : value[i](this.props)
      }
    }

    return {
      label: typeof value.label !== 'function' ? value.label : value.label(this.props),
      value: prop,
      valueFromField: value.value,
      ...addedProp
    }
  }

  computedProcess (config: any) {
    let filtered = config.filter((value: ObjType) => {
      return this.computedFilter(value)
    })

    return filtered.map((value: ObjType) => {
      return this.computedMapping(value)
    })
  }

  get getClientPerDsp () {
    return (dspConfig as ObjType)[this.dsp].client_value
  }
  get getDspClientLabel () {
    if (this.dsp === $GOOGLEADS) {
      return 'Manager Id'
    }
    return this.getClientPerDsp.ucwords().replace('_', ' ')
  }
  get getInsertionOrderLabelPerIo () {
    return (dspConfig as ObjType)[this.dsp].ioFieldLabel
  }
  get getInsertionOrderPropertyPerIo () {
    return (dspConfig as ObjType)[this.dsp].ioFieldAlert
  }
  get getAdLabelPerDsp () {
    return (dspConfig as ObjType)[this.dsp].adLabel
  }
  get getAdFieldPerDsp () {
    return (dspConfig as ObjType)[this.dsp].adField
  }
  get getIoFieldAlerts () {
    return (dspConfig as ObjType)[this.dsp].ioFieldAlert
  }
  get dsp (): InstructionDsp {
    return this.$route.params.dsp as InstructionDsp
  }

  @Watch('$route.params.dsp', { immediate: true })
  onParamsDspChange (newDsp: any) {
    if (this.$store.getters.getCurrentDsp !== newDsp) {
      this.$store.commit('setCurrentDsp', newDsp)
    }
  }
}
