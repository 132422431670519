
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class CopyClipboardComponent extends Vue {
  @Prop() toCopy: string | number
  @Prop({ default: 'Copy value to clipboard.' }) tooltipText: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: 'white' }) color: string
  @Prop({ default: true }) iconSmall: boolean
  @Prop({ default: false }) btnSmall: boolean
  @Prop({ default: false }) btnXSmall: boolean // small takes priority over x-small
  @Prop({ default: 'black' }) iconColor: ''
  @Prop({ default: true }) elevation: boolean
  @Prop() iconSize: string
  @Prop({ default: 'file_copy' }) icon: string
  @Prop({ default: 'ma-0' }) btnClass: string
  @Prop({ default: true }) disableIfCopyNil: boolean

  getIconStyle () {
    let style = `color: ${this.iconColor}`
    if (this.iconSize) {
      style += `; font-size: ${this.iconSize}`
    }
    return style
  }
  clipboard () {
    const copyTextValue = JSON.stringify(this.toCopy).replaceAll('"', '')
    navigator.clipboard.writeText(copyTextValue).then(() => {
      // success
    }, (e) => {
      // error
      console.error('Cannot copy this value !')
    })
  }
}
