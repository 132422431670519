import { apiCaller, EDIT, FORM_IS_OPEN, HAVE_JUST_DONE_BULK, NEW, NORMAL, Vue } from '../index'
import {
  $APPNEXUS,
  $BEESWAX,
  $DBM,
  $MEDIAMATH,
  $THETRADEDESK,
  $YOUTUBE,
  $KAYZEN,
  dspConfig,
  $META, $GOOGLEADS
} from '../../config/dspConfig'
import { getIoField } from '../../utils/instructionsUtils'
import _ from 'lodash'

// TODO : create a store for the IOForm

const state = {
  // set to false when created, set to true when all values from query request search
  // have been recovered
  // these 2 values need to be setted to true before the queryStringProcess make her process
  valuesFromRequestSearchRecovered: false,
  // set to false when created, set to true when all values from query request form
  // have been recovered
  valuesFromRequestFormRecovered: false,
  // register if yes or no a streaming is processing
  streamingProcessInProgress: false,
  currentSearch: {
    advertiser_id: '',
    member_id: '',
    partner_id: '',
    organization_id: '',
    retry: null,
    fast_retry: null,
    is_active: null,
    opti_auto: null,
    otto_min_viz: true,
    baseline_type: null,
    search: null
  },
  // contain the state of the app for special use case
  // YOU MUST RESET THE STATE TO NORMAL AFTER USE (with setAppToNormal for example)
  appState: NORMAL,
  // contain the status of the form. If no form is open, set it to null
  // used for queryString
  formStatus: null,
  // when form is on edit, contain the id of the item edited
  formId: null,
  // contain the object of the form item instruction
  formItem: null,
  // the default item
  // TODO : write a defaultItem for each dsp
  /**
   * @type {DspInstruction}
   */
  defaultItem: {
    id: '',
    group_name: '',
    advertiser_id: '',
    member_id: '',
    retry: false,
    fast_retry: false,
    is_active: true,
    opti_auto: false,
    otto_min_viz: false,
    reactivate_otto: false,
    objective: {
      KPI_CPA: '',
      max_CPM: '',
      min_viz: '',
      min_video_viz: '',
      offset: ''
    },
    use_opti_ratio_surcouche: false,
    opti_ratio: '',
    constraints_io: {
      margin_daily: '',
      margin_period: '',
      max_A: '',
      max_cutoff: '',
      mode: '',
      max_division: '',
      min_division: '',
      no_division: ''
    },
    revenue_type: '',
    true_conversion_funnel_pixel_ids: {},
    true_KPI_to_optimize: '',
    KPI_to_optimize: '',
    true_kpi_pixel_ids: '',
    optimize_campaigns: 'all',
    optimize_lineitems: 'all',
    // appnexus
    remove_daily_budget_global: false,
    remove_daily_budget_campaigns_only: false,
    remove_min_crate: true,
    remove_min_viz: true,
    keep_trusted_inventory: false,
    force_li_daily_pacing_asap: true,
    // appnexus
    remove_fold_position: true,
    overwrite_creative_selection: false,
    remove_time_parting: false,
    // dbm / mediamath
    keep_frequency: false,
    keep_budget_strat: false,
    // dbm
    keep_timeparting: false,
    keep_min_viz: false,
    force_pacing_asap_li: true,
    overwrite_daily_budget_li: true,
    executor_version: '1',
    use_custom_algo: true,
    // ttd
    overwrite_frequency: true,
    overwrite_daily_budget_impressions_adgroup: false,
    overwrite_daily_budget_money_adgroup: false,
    overwrite_viewability: false,
    overwrite_predictive_clearing: false,
    // mediamath / ttd
    remove_timeparting: true,
    remove_budget_strat_money: true,
    remove_budget_strat_imp: true,
    force_pacing_asap_money: false,
    force_pacing_asap_imp: true,
    // facebook
    use_adset_budgets: false,
    // youtube
    overwrite_frequency_target: true,
    overwrite_bid_adjustments_on_device: true,
    allow_switch_io_pacing: false,
    // youtube / dbm
    automatically_remove_deprecated_url: true,
    overwrite_li_budget: true,
    overwrite_unknown_demographic: 'never',
    waterfall_priority: 'delivery_first',
    KPI_rolling_period: null,
    // meta
    business_manager_id: '',
    overwrite_campaign_budget: true,
    //
    obj_watcher: {
      granularity: 'insertion_order',
      CPA: {
        value: ''
        // sensibility: 0.3,
        // pixel_id: null
      },
      saturation: {
        // sensibility: 0.2,
        io_alert: 'FAUX'
      },
      margin: {
        value: ''
        // sensibility: 0.3
      },
      min_viz: {
        value: ''
        // sensibility: 0.3
      },
      min_CPM: {
        value: ''
        // sensibility: 0.3
      },
      max_CPM: {
        value: ''
        // sensibility: 0.3
      },
      min_crate: {
        value: ''
      },
      KPI_to_optimize: {
        value: '',
        KPI: ''
      },
      true_KPI_to_optimize: {
        value: '',
        KPI: ''
      }
    },
    true_conv_measurement_tool: null,
    true_pivot_variable: null,
    strat_lvl: 'standard',
    p3_obj_watcher: {
      kpi_type: null,
      measurement_source: null,
      min_target_value: null,
      max_target_value: null,
      conversion_funnel_pixel_ids: null
    },
    p3_obj_watcher_alpha: 0.5,
    p3_obj_watcher_pivot_variables: []
  },
  // pass to true when the edit form is open and call the api for updating the updated item
  editIsUpdating: false,
  // setted to true when a value of the complex bar search is setted in with the querySearch.
  // when setted to true, the complex bar is displayed
  complexSearchFromQuery: false,
  instructionsInWarning: [],
  currentDsp: null,
  instructionStratAllowedDsp: [$APPNEXUS, $DBM, $THETRADEDESK, $MEDIAMATH, $BEESWAX, $YOUTUBE, $KAYZEN, $META, $GOOGLEADS],
  openDataForm: false,
  dataFormGroupKey: {},
  /**
   * The form status of the dataForm from alertings
   * in case the group key already exist, it is set to Edit, and the IoForm is not open but only a search in the searchbar is set
   */
  dataFormStatus: NEW,
  /**
   * @description the current overview of the instruction in the IoForm
   */
  currentOverview: null,
  /**
   * @type { BriefModel | null }
   */
  currentTraderBrief: null,
  /**
   * @description the current new feature references of the instruction in the IoForm
   * @type { NewFeatureReferenceModel[] | null }
   */
  currentNewFeatureReferences: null,
  optionsInstructions: {
    page: 1,
    itemsPerPage: 20
  },
  /**
   * @type { CheckPreMepResult | null }
   */
  checkPreMepResult: null,
  /**
   * @type { CheckValidatorResult | null }
   */
  checkValidatorResult: null,
  /**
   * @description In the IoForm, an attr "formPart" can be added to inputs with the attribute "tab" or parents component
   * with the attribute "tab" containing inputs.
   * When errors are triggered by input in the IoForm, if a formPart attribute is find, the formPart is added to the array.
   * See "displayBadgesIfErrors" in IoForm.vue and "BriefMarkupModule" for a usage example.
   */
  formPartWithError: [],
  /**
   * @type { Baseline[] }
   * @description the baselines of the current io opened in the IoForm
   */
  baselines: [],
  /**
   * @type { Baseline | null }
   * The current state of the baseline in the IoForm
   */
  currentBaseline: null,
  /**
   * @type { Baseline | null }
   * The starting state of the baseline in the IoForm (so the real current state in the database)
   */
  startBaseline: null,
  /**
   * @type { PivotVariable[] }
   * The current pivot variables of the current io opened in the IoForm
   */
  currentPivotVariables: [],
  /**
   * @type { EventOfIo[] | null }
   * The current events of the current io opened in the IoForm
   */
  currentEventsOfIo: [],
  inheritFromExpectedMargin: true,
  /**
   * @type { StatusInstruction | null }
   * The status of the instruction in the IoForm before any update.
   * Used to know if the instruction is in a state where the update is allowed.
   */
  startStatusInstruction: null,
  currentColdStartAvailability: null,
  /**
   * @type { KpiType[] | null }
   */
  kpiTypes: null,
  /**
   * @type { AvailableKpi[] | null }
   */
  availableKpis: null,
  /**
   * @type { OverviewAttribute | null }
   * The overview attributes of the current io opened in the IoForm
   */
  overviewAttributes: null,
  /**
   * @type { BriefConfigFieldsByDsp }
   */
  briefFieldsConfigByDsp: {}
}

const actions = {
  async openFormWithDataForm (context, dataForm) {
    console.warn('[overlay.js] opened via dataform')
    context.commit('setDataFormStatus', NEW)

    let dsp = dataForm.group_key.dsp

    // special case : from alertings group_key , TrueView is the dsp name for Youtube
    if (dsp === 'trueview') {
      dsp = $YOUTUBE
    }

    // special case beeswax, where prefix are removed from the surcouche (managed in back end api)
    if (dsp === $BEESWAX) {
      let toReplace = dataForm.group_key.client_id + '_'
      dataForm.group_key.advertiser_id = dataForm.group_key.advertiser_id.replace(toReplace, '')
    }
    let io = dataForm.group_key.insertion_order_id

    const isBulkAdd = dataForm.group_key.insertion_order_id === 'NONE' ||
      (dataForm.group_key.entity_type !== 'NONE' && dataForm.group_key.entity_id !== 'NONE')

    if (!isBulkAdd) {
      const results = await apiCaller.getInstructions(
        {
          [getIoField(dsp)]: io,
          dsp: dsp
        }, false)

      if (results && results.data.length) {
        console.warn('Io Already exist.')
        context.commit('setDataFormStatus', EDIT)
      }
    }

    // OPEN FORM AND FILL HER VALUES WITH THE GROUPKEY
    context.commit('setOpenDataForm', true)
    context.commit('setOpenDataFormGroupKey', dataForm)
  },
  /**
   * @param context
   * @param payload {{$router: VueRouter, $route: Route}} $route and $router instance must be property of payload for this function to work
   */
  closeDataFormProcess (context, payload) {
    let query = Object.assign({}, payload.$route.query)

    if ('data_form' in query) {
      delete query.data_form
      payload.$router.replace({ query })
    }

    context.commit('setOpenDataForm', false)
    context.commit('setOpenDataFormGroupKey', {})
  },
  async loadBriefFieldsConfigByDsp (context) {
    const response = await apiCaller.getBriefFieldsConfig()
    if (apiCaller.isResponseError(response)) {
      console.error('Error while loading brief fields config by dsp', response)
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      context.commit('setBriefFieldsConfigByDsp', response.data)
    }
  }
}

const mutations = {
  setCurrentSearch (state, currentSearch) {
    state.currentSearch = currentSearch
  },
  setAppState (state, appState) {
    if (appState !== NORMAL && appState !== HAVE_JUST_DONE_BULK && appState !== FORM_IS_OPEN) {
      throw new Error(`appState value must be one of them : ${NORMAL}, ${HAVE_JUST_DONE_BULK}, ${FORM_IS_OPEN}`)
    }
    state.appState = appState
  },
  setAppStateToNormal (state) {
    state.appState = NORMAL
    console.log('APP STATE NORMAL')
    // when app is set on normal, that mean than the form isn't open
    // thereby, form status form id and form item are setted to null
    state.formStatus = null
    state.formId = null
    state.formItem = null
  },
  setFormStatus (state, formStatus) {
    if (formStatus !== EDIT && formStatus !== NEW) {
      throw new Error(`formStatus value must be one of them : ${EDIT}, ${NEW}`)
    }
    Vue.set(state, 'formStatus', formStatus)
  },
  setFormId (state, formId) {
    if (typeof formId !== 'number') {
      throw new TypeError(`formId type must be number`)
    }
    state.formId = formId
  },
  setFormItem (state, formItem) {
    if (typeof formItem !== 'object') {
      throw new TypeError(`formItem type must be object`)
    }
    state.formItem = formItem

    if (formItem.id !== undefined && formItem.id !== null) {
      state.formId = formItem.id
    }
  },
  setValuesFromRequestSearchRecovered (state, bool) {
    if (typeof bool !== 'boolean') {
      throw new TypeError(`arg 'bool' must be a boolean`)
    }
    state.valuesFromRequestSearchRecovered = bool
  },
  setStreamingProcessInProgress (state, bool) {
    if (typeof bool !== 'boolean') {
      throw new TypeError(`arg 'bool' must be a boolean`)
    }
    state.streamingProcessInProgress = bool
  },
  setComplexSearchFromQuery (state, bool) {
    if (typeof bool !== 'boolean') {
      throw new TypeError(`arg 'bool' must be a boolean`)
    }
    state.complexSearchFromQuery = bool
  },
  editStartUpdate (state) {
    Vue.set(state, 'editIsUpdating', true)
  },
  editEndUpdate (state) {
    Vue.set(state, 'editIsUpdating', false)
  },
  clearInstructionsInWarning (state) {
    Vue.set(state, 'instructionsInWarning', [])
  },
  setInstructionsInWarning (state, arrayOfInstructions) {
    Vue.set(state, 'instructionsInWarning', arrayOfInstructions)
  },
  setCurrentDsp (state, dsp) {
    Vue.set(state, 'currentDsp', dsp)
  },
  setOpenDataForm (state, bool) {
    Vue.set(state, 'openDataForm', bool)
  },
  setOpenDataFormGroupKey (state, groupKey) {
    Vue.set(state, 'dataFormGroupKey', groupKey)
  },
  setDataFormStatus (state, status) {
    Vue.set(state, 'dataFormStatus', status)
  },
  setCurrentOverview (state, overview) {
    Vue.set(state, 'currentOverview', overview)
  },
  setCurrentTraderBrief (state, brief) {
    Vue.set(state, 'currentTraderBrief', brief)
  },
  setOptionsInstructions (state, options) {
    Vue.set(state, 'optionsInstructions', options)
  },
  setCheckPreMepResult (state, checkPreMepResult) {
    Vue.set(state, 'checkPreMepResult', checkPreMepResult)
  },
  setCheckValidatorResult (state, checkValidatorResult) {
    Vue.set(state, 'checkValidatorResult', checkValidatorResult)
  },
  setCurrentNewFeatureReferences (state, nfr) {
    Vue.set(state, 'currentNewFeatureReferences', nfr)
  },
  setFormPartWithError (state, formPartWithError) {
    Vue.set(state, 'formPartWithError', formPartWithError)
  },
  setBaselines (state, baselines) {
    Vue.set(state, 'baselines', baselines)
  },
  setCurrentBaseline (state, baseline) {
    Vue.set(state, 'currentBaseline', baseline)
  },
  /**
   * Will set the currentBaseline, and will clone it in startBaseline.
   * The goal is to have the starting state of the baseline object for the form.
   * In fact, depending on the starting state (who is the real state in database), all action are not allowed.
   * @param state
   * @param baseline
   */
  setStartBaseline (state, baseline) {
    Vue.set(state, 'currentBaseline', baseline)
    Vue.set(state, 'startBaseline', _.cloneDeep(baseline))
  },
  setCurrentPivotVariables (state, pivotVariables) {
    Vue.set(state, 'currentPivotVariables', pivotVariables)
  },
  setCurrentEventsOfIo (state, events) {
    Vue.set(state, 'currentEventsOfIo', events)
  },
  setInheritFromExpectedMargin (state, inheritFromExpectedMargin) {
    Vue.set(state, 'inheritFromExpectedMargin', inheritFromExpectedMargin)
  },
  setStartStatusInstruction (state, status) {
    Vue.set(state, 'startStatusInstruction', status)
  },
  setCurrentColdStartAvailability (state, coldStartAvailability) {
    Vue.set(state, 'currentColdStartAvailability', coldStartAvailability)
  },
  setKpiTypes (state, kpiTypes) {
    Vue.set(state, 'kpiTypes', kpiTypes)
  },
  setAvailableKpis (state, availableKpis) {
    Vue.set(state, 'availableKpis', availableKpis)
  },
  setOverviewAttributes (state, overviewAttributes) {
    Vue.set(state, 'overviewAttributes', overviewAttributes)
  },
  /**
   * @param state
   * @param briefFieldsConfigByDsp {BriefConfigFieldsByDsp}
   */
  setBriefFieldsConfigByDsp (state, briefFieldsConfigByDsp) {
    Vue.set(state, 'briefFieldsConfigByDsp', briefFieldsConfigByDsp)
  }
}

const getters = {
  getCurrentSearch: state => {
    return state.currentSearch
  },
  getCurrentSearchKeys: state => {
    return Object.keys(state.currentSearch)
  },
  getAppState: state => {
    return state.appState
  },
  getFormStatus: state => {
    return state.formStatus
  },
  getSteamingProcessInProgress: state => {
    return state.streamingProcessInProgress
  },
  getDefaultItem: state => {
    return state.defaultItem
  },
  getComplexSearchFromQuery: state => {
    return state.complexSearchFromQuery
  },
  getEditUpdateStatus: state => {
    return state.editIsUpdating
  },
  getInstructionsInWarning: state => {
    return state.instructionsInWarning
  },
  isInstructionStratEnabled (state) {
    return state.instructionStratAllowedDsp.indexOf(state.currentDsp) !== -1 && state.formStatus !== NEW
  },
  getOpenDataForm (state) {
    return state.openDataForm
  },
  getDataFormGroupKey (state) {
    return state.dataFormGroupKey
  },
  getDataFormStatus (state) {
    return state.dataFormStatus
  },
  getCurrentDsp (state) {
    return state.currentDsp
  },
  getCurrentOverview (state) {
    return state.currentOverview
  },
  /**
   * @param state
   * @returns {BriefModel}
   */
  getCurrentTraderBrief (state) {
    return state.currentTraderBrief
  },
  getOptionsInstructions (state) {
    return state.optionsInstructions
  },
  /**
   * @param state
   * @returns {CheckPreMepResult}
   */
  getCheckPreMepResult (state) {
    return state.checkPreMepResult
  },
  /**
   * @param state
   * @returns {CheckValidatorResult[]}
   */
  getCheckValidatorResult (state) {
    return state.checkValidatorResult
  },
  /**
   * @param state
   * @returns { NewFeatureReferenceModel[] | null }
   */
  getCurrentNewFeatureReferences (state) {
    return state.currentNewFeatureReferences
  },
  isCheckPreMepValid (state) {
    const validSetupState = ['good', 'non_blocking_editable', 'blocking_editable']
    const checkPreMepResult = state.checkPreMepResult
    return checkPreMepResult && validSetupState.includes(checkPreMepResult.check_setup_state)
  },
  getFormPartWithError (state) {
    return state.formPartWithError
  },
  getBaselines (state) {
    return state.baselines
  },
  getCurrentBaseline (state) {
    return state.currentBaseline
  },
  getStartBaseline (state) {
    return state.startBaseline
  },
  getCurrentPivotVariables (state) {
    return state.currentPivotVariables
  },
  getCurrentEventsOfIo (state) {
    return state.currentEventsOfIo
  },
  getInheritFromExpectedMargin (state) {
    return state.inheritFromExpectedMargin
  },
  getStartStatusInstruction (state) {
    return state.startStatusInstruction
  },
  getCurrentColdStartAvailability (state) {
    return state.currentColdStartAvailability
  },
  getKpiTypes (state) {
    return state.kpiTypes
  },
  getAvailableKpis (state) {
    return state.availableKpis
  },
  getOverviewAttributes (state) {
    return state.overviewAttributes
  },
  getBriefFieldsConfigByDsp (state) {
    return state.briefFieldsConfigByDsp
  }
}

export const overlay = {
  actions,
  mutations,
  state,
  getters
}
